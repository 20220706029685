import { Fragment, memo, useState } from 'react';
import classes from './styles.module.scss';
import { Sidebar as ReactProSidebar } from 'react-pro-sidebar';
import {
  AppleIcon,
  CloseIcon,
  DesktopAppIcon,
  HomeIcon,
  LinuxIcon,
  LogoIcon,
  PinIcon,
  ProjectIcon,
  ScheduleIcon,
  UserIcon,
  UserPlaceholderImage,
  WindowIcon,
} from 'assets';
import { ESidebarMenu } from 'configs/enums';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { IReducer } from 'redux/reducers';
import { routes } from 'routers/routes';
import useRouteMatch from 'hooks/useRouteMatch';
import ToastService from 'services/toast_service';
import Messages from 'configs/messages';
import { NULL_VALUE } from 'configs/constant';
import { setArchivedProjectListReducer, setLogoutInformationReducer, setProjectListReducer } from 'redux/reducers/Information/actionTypes';
import { Dropdown } from 'react-bootstrap';
import AuthService from 'services/auth_service';
import { setLogoutUserReducer } from 'redux/reducers/User/actionTypes';
import { setLogoutWorkspaceReducer } from 'redux/reducers/Workspace/actionTypes';
import clsx from 'clsx';
import { Tooltip } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ApiService from 'services/api_service';
import ApiRoutes from 'configs/apiRoutes';
import { setIsLoadingReducer } from 'redux/reducers/Status/actionTypes';

interface SidebarProps { }

const Sidebar: React.FC<SidebarProps> = memo((props: SidebarProps) => {
  const dispatch = useDispatch();
  const { isExact: isHomePage } = useRouteMatch(routes.private.home);
  const { isExact: isProjectPage } = useRouteMatch(routes.private.project.default);
  const { isExact: isProjectTaskViewPage } = useRouteMatch(routes.private.project.taskView);
  const { isExact: isUsersPage } = useRouteMatch(routes.private.users);
  const { isExact: isSchedulerPage } = useRouteMatch(routes.private.project.scheduler);
  const { isExact: isFramePage } = useRouteMatch(routes.private.project.frame);

  const { user } = useSelector((state: IReducer) => state?.user);
  const { workspace, project, pipeline } = useSelector((state: IReducer) => state?.workspace);
  const { resource } = useSelector((state: IReducer) => state?.information);

  const [isOpenDesktopApp, setIsOpenDesktopApp] = useState<boolean>(false);

  const onSelectItem = (type: ESidebarMenu) => {
    switch (type) {
      case ESidebarMenu.Home:
        if (!workspace?._id) {
          dispatch(setProjectListReducer(null));
          dispatch(setArchivedProjectListReducer(null));
        }
        dispatch(
          push({
            pathname: routes.private.home,
            search: `&workspaceId=${workspace?._id ?? NULL_VALUE}`,
          })
        );
        break;
      case ESidebarMenu.Project:
        if (!project) {
          ToastService.info(Messages.info.projectNotFound);
        } else {
          dispatch(
            push({
              pathname: routes.private.project.default
                .replace(':projectId', project?._id)
                .replace(
                  ':pipelineId',
                  project?.pipeline_ids?.includes(pipeline?._id) ? pipeline?._id : project?.pipeline_ids?.length ? project?.pipeline_ids?.[0] : NULL_VALUE
                ),
              search: `&workspaceId=${workspace?._id ?? NULL_VALUE}`,
            })
          );
        }
        break;
      case ESidebarMenu.Scheduler:
        if (!project) {
          ToastService.info(Messages.info.noProjectsCreated);
        } else {
          dispatch(
            push({
              pathname: routes.private.project.scheduler.replace(':workspaceId', workspace?._id),
            })
          );
        }
        break;
      case ESidebarMenu.Users:
        dispatch(push({ pathname: routes.private.users, search: `&workspaceId=${workspace?._id ?? NULL_VALUE}` }));
        break;
      case ESidebarMenu.Review:
        if (!project) {
          ToastService.info(Messages.info.noProjectsCreated);
        } else {
          dispatch(
            push({
              pathname: routes.private.project.frame.replace(':projectId', project?._id)
              .replace(
                ':pipelineId',
                project?.pipeline_ids?.includes(pipeline?._id) ? pipeline?._id : project?.pipeline_ids?.length ? project?.pipeline_ids?.[0] : NULL_VALUE
              )
              .replace(':resourceId', resource?.id ?? NULL_VALUE),
              search: `&workspaceId=${workspace?._id ?? NULL_VALUE}`,
            })
          );
        }
        break;
      default:
        break;
    }
  };

  const onGetDesktopApp = (os: string) => {
    dispatch(setIsLoadingReducer(true));
    ApiService.GET(ApiRoutes.desktopApp.default.replace(':os', os))
      .then((response) => {
        if (response?.url) {
          window?.open(response?.url, '_blank', 'noopener,noreferrer');
        } else {
          ToastService.error(Messages.error.default);
        }
      })
      .catch((error) => {
        console.log(error);
        ToastService.error(Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  const onLogout = () => {
    AuthService.logout();

    dispatch(setLogoutInformationReducer());
    dispatch(setLogoutUserReducer());
    dispatch(setLogoutWorkspaceReducer());

    dispatch(push(routes.public.getStarted));
  };

  const handleTooltipClose = () => {
    setIsOpenDesktopApp(false)
  }

  return (
    <ReactProSidebar
      width="60px"
      rootStyles={{
        position: 'fixed',
        top: 0,
        left: 0,
        border: 'none',
        boxShadow: 'var(--rightBoxShadow)',
        zIndex: 9999,
        '.ps-sidebar-container': {
          overflow: 'visible',
        },
      }}
    >
      <div className={classes.container}>
        <div className={classes.menu}>
          <div className={classes.logoItem} onClick={() => onSelectItem(ESidebarMenu.Home)}>
            <LogoIcon />
          </div>

          <div className={classes.divider}></div>

          <div className={classes.item} onClick={() => onSelectItem(ESidebarMenu.Home)}>
            <HomeIcon
              className={clsx(classes.pathFill, {
                [classes.active]: isHomePage,
              })}
            />
          </div>

          <div className={classes.item} onClick={() => onSelectItem(ESidebarMenu.Project)}>
            <ProjectIcon
              className={clsx(classes.projectIcon, {
                [classes.active]: isProjectPage || isProjectTaskViewPage,
              })}
            />
          </div>

          <div className={classes.item} onClick={() => onSelectItem(ESidebarMenu.Scheduler)}>
            <ScheduleIcon
              className={clsx(classes.rectFill, {
                [classes.active]: isSchedulerPage,
              })}
            />
          </div>

          <div className={classes.item} onClick={() => onSelectItem(ESidebarMenu.Users)}>
            <UserIcon
              className={clsx(classes.userIcon, {
                [classes.active]: isUsersPage,
              })}
            />
          </div>

          <div className={classes.item} onClick={() => onSelectItem(ESidebarMenu.Review)}>
            <PinIcon
              className={clsx(classes.pathStroke, {
                [classes.active]: isFramePage,
              })}
            />
          </div>

          <div className={classes.userItem}>
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <Tooltip
                open={isOpenDesktopApp}
                arrow={true}
                placement="right"
                componentsProps={{ popper: { sx: { zIndex: 99999 } }, tooltip: { className: classes.tooltip } }}
                title={
                  <Fragment>
                    <div className={classes.top}>
                      <p>Download the desktop app</p>
                      <CloseIcon onClick={() => setIsOpenDesktopApp(false)} />
                    </div>
                    <div className={classes.bottom}>
                      <LinuxIcon onClick={() => onGetDesktopApp('linux_latest')} />
                      <AppleIcon onClick={() => onGetDesktopApp('mac_latest')} />
                      <WindowIcon onClick={() => onGetDesktopApp('windows_latest')} />
                    </div>
                  </Fragment>
                }
              >
                <DesktopAppIcon className={classes.desktopAppIcon} onClick={() => setIsOpenDesktopApp(!isOpenDesktopApp)} />
              </Tooltip>
            </ClickAwayListener>
            <Dropdown drop="end">
              <Dropdown.Toggle>
                <img className={classes.avatar} src={user?.profile_image_thumbnail_blob ?? user?.profile_image_url ?? UserPlaceholderImage} alt="Avatar" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => dispatch(push({ pathname: routes.private.account, search: `&workspaceId=${workspace?._id ?? NULL_VALUE}` }))}
                >
                  <span>Account</span>
                </Dropdown.Item>
                <Dropdown.Item onClick={onLogout}>
                  <span>Logout</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </ReactProSidebar>
  );
});

export default Sidebar;
