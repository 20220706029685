import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classes from './styles.module.scss';
import { LinkIcon, UploadIcon, UserPlaceholderImage } from 'assets';
import * as Yup from 'yup';
import Regexes from 'configs/regexes';
import Input from 'components/Input';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ToastService from 'services/toast_service';
import Messages from 'configs/messages';
import ApiService from 'services/api_service';
import ApiRoutes from 'configs/apiRoutes';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoadingReducer } from 'redux/reducers/Status/actionTypes';
import { IReducer } from 'redux/reducers';
import { Skeleton } from '@mui/material';
import { setUserReducer } from 'redux/reducers/User/actionTypes';
import UserService from 'services/user_service';
import CommonService from 'services/common_service';
import { Container } from 'react-bootstrap';
import Header from 'components/Header';
import { MAX_IMAGE_SIZE } from 'configs/constant';
import { EStatusCode } from 'configs/enums';
import { billingLink, subscriptionLink } from 'configs/links';

interface IChangePasswordFromData {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

interface AccountPageProps {}

const AccountPage: React.FC<AccountPageProps> = memo((props: AccountPageProps) => {
  const dispatch = useDispatch();

  const { user } = useSelector((state: IReducer) => state?.user);

  const imageInputRef = useRef<HTMLInputElement>();

  const [avatar, setAvatar] = useState<string>(null);
  const [image, setImage] = useState<string>('');

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        oldPassword: Yup.string().required('Please enter your old password.'),
        newPassword: Yup.string()
          .required('Please enter your new password.')
          .matches(Regexes.password, 'Password must be least 8 characters including an upper case, an lower case, a number, and a special character.'),
        confirmNewPassword: Yup.string()
          .required('Please confirm your new password.')
          .oneOf([Yup.ref('newPassword'), null], 'Confirm new password does not match.'),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<IChangePasswordFromData>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const watchNewPassword = watch('newPassword');
  const watchConfirmNewPassword = watch('confirmNewPassword');

  useEffect(() => {
    if (user) {
      setAvatar(user?.profile_image_thumbnail_blob ?? user?.profile_image_url ?? UserPlaceholderImage);
    }
  }, [user]);

  useEffect(() => {
    if (image) {
      onSubmitImage();
    }
  }, [image]);

  useEffect(() => {
    if (watchNewPassword !== watchConfirmNewPassword && watchConfirmNewPassword !== '') {
      setError('confirmNewPassword', {
        type: 'match',
        message: 'Confirm new password does not match',
      });
    } else if (watchNewPassword === watchConfirmNewPassword && watchConfirmNewPassword !== '') {
      clearErrors('confirmNewPassword');
    }
  }, [watchConfirmNewPassword, watchNewPassword]);

  const onChangeImage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const imageFile: File = event?.target?.files?.[0];

    if (imageFile?.size >= MAX_IMAGE_SIZE) {
      return ToastService.error(Messages.error.imageSize);
    }

    if (imageFile) {
      const reader = new FileReader();
      reader.onload = (imageBase64: ProgressEvent<FileReader>) => {
        if (imageBase64?.target) {
          setImage(imageBase64?.target?.result as string);
        }
      };
      reader.readAsDataURL(imageFile);
    }
  }, []);

  const onSubmitImage = () => {
    dispatch(setIsLoadingReducer(true));
    ApiService.POST(ApiRoutes.user.avatar.replace(':userId', user?._id), [
      {
        content: image,
      },
    ])
      .then((response) => {
        const updatedUser = {
          ...user,
          profile_image_url: response?.entities?.[0]?.profile_image_url ?? user?.profile_image_url,
          profile_image_thumbnail_blob: response?.entities?.[0]?.profile_image_thumbnail_blob ?? user?.profile_image_thumbnail_blob
        };
        dispatch(setUserReducer(updatedUser));
        setImage('');
        UserService.setUser(updatedUser);
        ToastService.success(Messages.success.default);
      })
      .catch((error) => {
        if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
          ToastService.error(Messages.error.accessDenied);
          return;
        }
        console.log(error);
        ToastService.error(Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  const onSubmitPassword = useCallback((data: IChangePasswordFromData) => {
    dispatch(setIsLoadingReducer(true));
    CommonService.sha256(data?.oldPassword)
      .then(async (hashedOldPassword) => {
        await CommonService.sha256(data?.newPassword)
          .then(async (hashedNewPassword) => {
            await ApiService.POST(ApiRoutes.auth.changePassword, {
              password: hashedOldPassword,
              new_password: hashedNewPassword,
            })
              .then((response) => {
                console.log(response);
                ToastService.success(Messages.success.default);
              })
              .catch((error) => {
                if (error?.response?.status === EStatusCode.AccessDenied && error?.response?.data?.errorType === 'Access') {
                  ToastService.error(Messages.error.accessDenied);
                  return;
                }
                console.log(error);
                ToastService.error(Messages.error.default);
              });
          })
          .catch((error) => {
            console.log(error);
            ToastService.error(Messages.error.default);
          });
      })
      .catch((error) => {
        console.log(error);
        ToastService.error(Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  }, []);

  return (
    <Container fluid>
      <Header className="mb-1" />

      <p className={classes.description}>Update profile information such as, profile picture, and password.</p>

      {/* PROFILE */}
      <div className={classes.section}>
        <p className={classes.title}>Profile picture</p>
        {avatar ? (
          <div className={classes.avatar} onClick={() => imageInputRef?.current?.click()}>
            <input
              ref={imageInputRef}
              type="file"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChangeImage(event)}
              accept="image/png, image/jpg, image/jpeg"
            />
            <img src={avatar ?? UserPlaceholderImage} alt="Avatar" />
            <UploadIcon />
          </div>
        ) : (
          <Skeleton width={100} height={100} variant="circular" sx={{ background: 'var(--backgroundLight)' }} />
        )}
      </div>

      {/* CHANGE PASSWORD */}
      <div className={classes.section}>
        <p className={classes.title}>Change password</p>
        <form className={classes.changePasswordForm} onSubmit={handleSubmit(onSubmitPassword)}>
          {user?.is_sso_user_only ? null : (
            <Input
              label="Old password"
              inputRef={register('oldPassword')}
              type="password"
              placeholder="Your old password"
              errorMessage={errors?.oldPassword?.message}
            />
          )}

          <Input
            label="New password"
            inputRef={register('newPassword')}
            type="password"
            placeholder="Your new password"
            errorMessage={errors?.newPassword?.message}
          />

          <Input
            className="mb-4"
            label="Confirm new password"
            inputRef={register('confirmNewPassword')}
            type="password"
            placeholder="Conform your new password"
            errorMessage={errors?.confirmNewPassword?.message}
          />

          <PrimaryButton className="mt-4" type="submit">
            Update password
          </PrimaryButton>
        </form>
      </div>

      <div className={classes.section}>
        <p className={classes.title}>Subscription</p>
        {user?.is_customer ? (
          <a className={classes.billingButton} href={billingLink + process.env.REACT_APP_PUBLISH_KEY} target="_blank" rel="noreferrer">
          <PrimaryButton>
            <p>Manage</p>
            <LinkIcon />
          </PrimaryButton>
        </a>
        ) : (
          <a className={classes.billingButton} href={subscriptionLink} target="_blank" rel="noreferrer">
          <PrimaryButton>
            <p>Manage</p>
            <LinkIcon />
          </PrimaryButton>
        </a>
        )}
        
      </div>
    </Container>
  );
});

export default AccountPage;
