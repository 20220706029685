import { Fragment, memo, useEffect, useRef, useState } from 'react';
import classes from './styles.module.scss';
import Select from 'components/Select';
import { EGanttToolbarItem, EScheduleLayer, ESelectTheme } from 'configs/enums';
import { useDispatch, useSelector } from 'react-redux';
import { IReducer } from 'redux/reducers';
import { ISelectOption } from 'interfaces/common';
import { IWorkspace } from 'interfaces/workspace';
import { push } from 'connected-react-router';
import { routes } from 'routers/routes';
import { setArchivedProjectListReducer, setProjectListReducer } from 'redux/reducers/Information/actionTypes';
import { setWorkspaceReducer } from 'redux/reducers/Workspace/actionTypes';
import { CircularProgress, Stack, Switch } from '@mui/material';
import { IProject } from 'interfaces/project';
import { ALL_OPTION, NULL_OPTION, NULL_VALUE } from 'configs/constant';
import {
  ColumnDirective,
  ColumnsDirective,
  GanttComponent,
  IGanttData,
  Inject,
  Toolbar,
  TaskFieldsModel,
  Gantt,
  Selection,
  Edit,
  ITaskbarEditedEventArgs,
  Resize,
  GridLine
} from '@syncfusion/ej2-react-gantt';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import { ICollabUser, IUser } from 'interfaces/user';
import { CollapseIcon, DownloadIcon, ExpandIcon, UserPlaceholderImage } from 'assets';
// import clsx from 'clsx';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { EmitType } from '@syncfusion/ej2-base';
import UserPopover from 'pages/Project/components/Popovers/User';
import { IProjectResourceGroupedTasks, IResource, IResourceGroupedTasks, IResponseTask, IUpdateResourcePayload } from 'interfaces/pipeline';
import ApiService from 'services/api_service';
import ApiRoutes from 'configs/apiRoutes';
import ToastService from 'services/toast_service';
import Messages from 'configs/messages';
import { setIsLoadingReducer } from 'redux/reducers/Status/actionTypes';
import DateRangePicker from 'pages/Project/components/DateRangePicker';

Gantt.Inject(Selection, Edit, Resize);

interface IGanttTask {
  _id: string;
  name: string;
  subTasks?: IGanttTask[];
  resource_id?: string;
  sub_resource_id?: string;
  progress?: number;
  duration?: number;
  dates: string;
  start_date_time?: Date;
  end_date_time?: Date;
  assigned_user?: IUser;
  dependency?: string[];
  layer: EScheduleLayer;
}

interface IAssignees {
  assigneeThumbnailUrl: string;
  assigneeThumbnailBlob: string;
  assigneeIds: string[];
}

interface SchedulerPageProps {}

interface UpdateTaskBarProps {
  start_date_time?: Date;
  end_date_time?: Date;
  assigned_user_id?: string;
}
interface UpdateResourceBarProps {
  start_date_time?: Date;
  end_date_time?: Date;
}
const SchedulerPage: React.FC<SchedulerPageProps> = memo(() => {
  const dispatch = useDispatch();

  const { workspace } = useSelector((state: IReducer) => state?.workspace);
  const { workspaceList, projectList } = useSelector((state: IReducer) => state?.information);

  const ganttChartRef = useRef<GanttComponent>();
  const updateResourcePayload = useRef<IUpdateResourcePayload>();

  const [ganttTaskList, setGanttTaskList] = useState<IGanttTask[]>(null);
  // const [rawGanttData, setRawGanttData] = useState<IGanttTask[]>(null);
  const [userPopoverAnchorElement, setUserPopoverAnchorElement] = useState<HTMLElement>(null);
  const [workspaceOptions, setWorkspaceOptions] = useState<ISelectOption[]>([]);
  const [projectOptions, setProjectOptions] = useState<ISelectOption[]>([]);
  // const [hideProjectLayer, setHideProjectLayer] = useState<boolean>(false);
  const [hideLabels, setHideLabels] = useState<boolean>(false);
  const [disableZoomIn] = useState<boolean>(false);
  const [disableZoomOut] = useState<boolean>(false);
  // const [dataBinding, setDataBinding] = useState<boolean>(false);
  const [gridLines, setGridlines] = useState<GridLine>('Both');
  const [showProgress] = useState<boolean>(true);
  const [showAssignedUser] = useState<boolean>(false);
  // const [showEstimatedHours, setShowEstimatedHours] = useState<boolean>(false);

  const taskFields: TaskFieldsModel = {
    id: '_id',
    name: 'name',
    startDate: 'start_date_time',
    endDate: 'end_date_time',
    // duration: 'duration',
    progress: 'progress',
    resourceInfo: 'assigned_user',
    child: 'subTasks',
    // dependency: 'dependency',
  };

  // const hiddenProjectLayerGanttData: IGanttTask[] = useMemo(() => {
  //   return ganttTaskList ? ganttTaskList?.flatMap((projectData) => projectData?.subTasks ?? []) : [];
  // }, [ganttTaskList]);

  useEffect(() => {
    if (projectList?.length) {
      setGanttTaskList(null);
      dispatch(setIsLoadingReducer(true));
      ApiService.GET(ApiRoutes.task.default, {
        filter_project_id: projectList?.map((item) => item?._id)?.join(','),
        groupBy: 'project_id,resource_id',
      })
        .then((response) => {
          setGanttTaskList(
            response?.entities?.map((entity: IProjectResourceGroupedTasks) => {
              const project: IProject = entity?.project;
              const resources: IResourceGroupedTasks[] = entity?.resources;
              return {
                _id: project?._id,
                name: project?.name,
                layer: EScheduleLayer.Project,
                start_date_time: new Date(project?.start_date),
                end_date_time: new Date(project?.end_date),
                subTasks: resources?.map((item) => {
                  const resource: IResource = item?.resource;
                  const tasks: IResponseTask[] = item?.tasks;
                  return {
                    _id: resource?._id,
                    name: resource?.name,
                    start_date_time: resource?.start_date_time ? new Date(resource?.start_date_time) : null,
                    end_date_time: resource?.end_date_time ? new Date(resource?.end_date_time) : null,
                    layer: EScheduleLayer.Resource,
                    subTasks: projectList?.length === 1 ? tasks?.map((task) => {
                      return {
                        _id: task._id || `${task.resource_id}-${task.sub_resource_id}`,
                        resource_id: task.resource_id,
                        sub_resource_id: task.sub_resource_id,
                        name: task.sub_resource_name,
                        layer: EScheduleLayer.Task,
                        start_date_time: task?.start_date_time ? new Date(task?.start_date_time) : null,
                        end_date_time: task?.end_date_time ? new Date(task?.end_date_time) : null,
                        progress: 0,
                        duration: task?.duration_days,
                        assigned_user: task.assigned_user?.first_name || null
                      };
                    }) : [],
                  };
                }),
              };
            })
          );
          // setShowAssignedUser(projectList.length === 1);
          // setShowProgress(projectList.length === 1);
        })
        .catch((error) => {
          console.log(error);
          ToastService.error(Messages.error.default);
        })
        .finally(() => dispatch(setIsLoadingReducer(false)));
    }
  }, [projectList]);

  useEffect(() => {
    if (workspaceList) {
      setWorkspaceOptions(
        workspaceList?.map((workspace: IWorkspace) => {
          return {
            value: workspace?._id,
            label: workspace?.name,
          };
        })
      );
    }
  }, [workspaceList]);

  useEffect(() => {
    if (projectList) {
      setProjectOptions(
        projectList?.map((project: IProject) => {
          return {
            value: project?._id,
            label: project?.name,
          };
        })
      );
    }
  }, [projectList]);

  useEffect(() => {
    if (ganttChartRef?.current?.labelSettings) {
      ganttChartRef.current.labelSettings.rightLabel = hideLabels ? null : 'name';
    }
  }, [hideLabels]);

  // useEffect(() => {
  //   if (hideProjectLayer) {
  //     setRawGanttData(ganttTaskList);
  //     setGanttTaskList(hiddenProjectLayerGanttData);
  //     ganttChartRef.current?.expandAll();
  //   } else {
  //     setGanttTaskList(rawGanttData);
  //   }
  //   setDataBinding(true);
  // }, [hideProjectLayer]);

  const progressTemplate = (props: IGanttData) => {
    const progress = calculateTotalProgress(props);
    return (
      <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'space-between'} sx={{ maxWidth: '90px' }}>
        <p>{(progress * 100).toFixed(0)}%</p>
        <CircularProgress className={classes.progressBar} size={24} thickness={9} variant="determinate" value={progress * 100} />
      </Stack>
    );
  };

  // const hourTemplate = (props: IGanttData) => {
  //   const initialLevel = props?.level === (props?.taskData as IGanttTask)?.layer ? 0 : 1;
  //   if (initialLevel === 0 && props?.level === 0) return null;
  //   let totalHours = (props?.taskData as IGanttTask)?.duration ?? 0;
  //   if (props?.childRecords?.length > 0) {
  //     totalHours += props?.childRecords?.reduce((acc: number, child: IGanttData) => (acc += (child?.taskData as IGanttTask)?.duration ?? 0), 0);
  //   }
  //   return <p>{totalHours}</p>;
  // };

  const assignTemplate = (props: IGanttData) => {
    const initialLevel = props?.level === (props?.taskData as IGanttTask)?.layer ? 0 : 1;
    const assignees = calculateTotalAssignees(props);
    if (assignees?.assigneeIds?.length > 1) {
      return (
        <Stack className={classes.assigneeChip} direction={'row'} spacing={1} alignItems={'center'}>
          <img className={classes.assigneeImg} src={assignees?.assigneeThumbnailUrl ?? UserPlaceholderImage} alt="" />
          <p>{assignees?.assigneeIds?.length ? `+${assignees?.assigneeIds?.length - 1}` : null}</p>
        </Stack>
      );
    } else {
      return props?.level === 2 - initialLevel ? (
        <div
          id={`${(props?.taskData as IGanttTask)?._id}`}
          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            event?.stopPropagation();
            updateResourcePayload.current = {
              id: '',
              name: '',
              task: null,
            };
            onClickUserPopover(event);
          }}
        >
          <img style={{ cursor: 'pointer' }} className={classes.assigneeImg} src={assignees?.assigneeThumbnailUrl ?? UserPlaceholderImage} alt="" />
        </div>
      ) : (
        <img className={classes.assigneeImg} src={assignees?.assigneeThumbnailUrl ?? UserPlaceholderImage} alt="" />
      );
    }
  };

  const dateTemplate = (gantData: IGanttData) => {
    const taskData = gantData?.taskData as IGanttTask;  
    return (
      <div
        id={`${taskData?._id}`}
        onClick={(event: React.MouseEvent<HTMLDivElement>) => {
          event?.stopPropagation();          
        }}
      >
        <DateRangePicker
          startDate={taskData.start_date_time}
          endDate={taskData.end_date_time}
          placeholder='Dates'
          className={classes.dateRangePicker}
          disablePresets={true}
          onChange={(date: any) => {
            if (gantData?.level === 0) {
              // Update project dates
              updateProject(gantData, {
                start_date_time: date.startDate,
                end_date_time: date.endDate,
              });
            } else if (gantData?.level === 1) {
              // Update resource dates
              updateResource(gantData, {
                start_date_time: date.startDate,
                end_date_time: date.endDate,
              })
            } else {
              updateTask(gantData, {
                start_date_time: date.startDate,
                end_date_time: date.endDate,
              });
            }
          }}
        />
      </div>
    );
  }

  const calculateTotalProgress = (props: IGanttData): number => {
    if (props?.level === 0) {
      let totalProgress = 0;
      props?.childRecords?.forEach((child: IGanttData) => {
        totalProgress += calculateTotalProgress(child);
      });
      return totalProgress / props?.childRecords?.length;
    } else {
      let progress = (props?.taskData as IGanttTask)?.progress ?? 0;
      if (props?.level === 1 && props?.childRecords?.length > 0) {
        const totalProcess = props?.childRecords?.reduce((acc: number, child: IGanttData) => (acc += (child?.taskData as IGanttTask)?.progress ?? 0), 0);
        progress = totalProcess / props?.childRecords?.length;
      }
      return progress;
    }
  };

  const calculateTotalAssignees = (props: IGanttData): IAssignees => {
    if (props?.level === 0) {
      let assignees: IAssignees = {
        assigneeThumbnailUrl: null,
        assigneeThumbnailBlob: null,
        assigneeIds: [],
      };
      props?.childRecords?.forEach((child: IGanttData) => {
        const childTaskAssignee = calculateTotalAssignees(child);
        assignees = {
          ...assignees,
          assigneeThumbnailUrl: assignees?.assigneeThumbnailUrl ? assignees?.assigneeThumbnailUrl : childTaskAssignee?.assigneeThumbnailUrl,
          assigneeIds: [...(assignees?.assigneeIds ?? []), ...(childTaskAssignee?.assigneeIds ?? [])],
        };
      });
      return assignees;
    } else {
      let assignees: IAssignees = {
        assigneeThumbnailUrl: (props?.taskData as IGanttTask)?.assigned_user?.profile_image_url ?? null,
        assigneeThumbnailBlob: (props?.taskData as IGanttTask)?.assigned_user?.profile_image_thumbnail_blob ?? null,
        assigneeIds: (props?.taskData as IGanttTask)?.assigned_user ? [(props?.taskData as IGanttTask)?.assigned_user?._id] : [],
      };
      if (props?.level === 1 && props?.childRecords?.length > 0) {
        assignees = props?.childRecords?.reduce(
          (assigneesAcc, record) => ({
            ...assigneesAcc,
            assigneeThumbnailUrl: assigneesAcc?.assigneeThumbnailUrl
              ? assigneesAcc?.assigneeThumbnailUrl
              : (record?.taskData as IGanttTask)?.assigned_user?.profile_image_url ?? null,
            assigneeIds: assigneesAcc?.assigneeIds?.includes((record?.taskData as IGanttTask)?.assigned_user?._id)
              ? assigneesAcc?.assigneeIds
              : [...(assigneesAcc?.assigneeIds ?? []), (record?.taskData as IGanttTask)?.assigned_user?._id],
            assigneeThumbnailBlob: assigneesAcc?.assigneeThumbnailBlob
              ? assigneesAcc?.assigneeThumbnailBlob
              : (record?.taskData as IGanttTask)?.assigned_user?.profile_image_thumbnail_blob ?? null
          }),
          assignees
        );
      }
      return assignees;
    }
  };

  const updateTask = (ganttData: IGanttData, props: UpdateTaskBarProps) => {
    dispatch(setIsLoadingReducer(true));
    const taskData = ganttData?.taskData as IGanttTask;
    let url = ''
    const {start_date_time, end_date_time, assigned_user_id } = props;
    const updateObj = {}
    if (start_date_time) {
      updateObj['start_date_time'] = start_date_time
    }
    if (end_date_time) {
      updateObj['end_date_time'] = end_date_time
    }
    if (assigned_user_id) {
      updateObj['assigned_user_id'] = assigned_user_id
    }
    if (taskData._id.split('-').length === 2) {
      url = `${ApiRoutes.task.default}?filter_resource_id=${taskData.resource_id}&filter_sub_resource_id=${taskData.sub_resource_id}`
    } else {
      url = ApiRoutes.task.update.replace(':taskId', taskData._id)
    }
    ApiService.PATCH(url, updateObj)
    .then((response) => {
      if (response?.status === 200) {
        ToastService.success(Messages.success.default);
      }
      // Update redux
      // dispatch(
      //   setResourceListReducer({})
      // )
    })
    .catch((error) => {
      console.log(error);
      ToastService.error(Messages.error.default);
    })
    .finally(() => dispatch(setIsLoadingReducer(false)));      
  };

  const updateResource = (ganttData: IGanttData, props: UpdateResourceBarProps) => {
    const taskData = ganttData?.taskData as IGanttTask;
    const {start_date_time, end_date_time } = props;
    dispatch(setIsLoadingReducer(true));
    const updateObj = {}
    if (start_date_time) {
      updateObj['start_date_time'] = start_date_time
    }
    if (end_date_time) {
      updateObj['end_date_time'] = end_date_time
    }
    ApiService.PATCH(ApiRoutes.resource.info.replace(':resourceId', taskData._id), updateObj)
    .then((response) => {
      if (response?.status === 200) {
        ToastService.success(Messages.success.default);
      }
    })
    .catch((error) => {
      console.log(error);
      ToastService.error(Messages.error.default);
    })
    .finally(() => dispatch(setIsLoadingReducer(false)));
  }

  const updateProject = (ganttData: IGanttData, props: UpdateTaskBarProps) => {
    const taskData = ganttData?.taskData as IGanttTask;
    const {start_date_time, end_date_time } = props;
    dispatch(setIsLoadingReducer(true));
    const updateObj = {}
    if (start_date_time) {
      updateObj['start_date'] = start_date_time
    }
    if (end_date_time) {
      updateObj['end_date'] = end_date_time
    }
    ApiService.PATCH(ApiRoutes.project.info.replace(':projectId', taskData._id), updateObj)
    .then((response) => {
      if (response?.status === 200) {
        ToastService.success(Messages.success.default);
      }
    })
    .catch((error) => {
      console.log(error);
      ToastService.error(Messages.error.default);
    })
    .finally(() => dispatch(setIsLoadingReducer(false)));
  }
  const updateTaskBarDates = (event: ITaskbarEditedEventArgs) => {
    // Check level of event data
    const level = event.data.level;
    const ganttTask = (event.data.taskData as IGanttTask)
    // Compare end and start dates of event.data and ganttTask to see if changed
    const updateData = {}
    if (ganttTask.start_date_time !== event.previousData.startDate) {
      updateData['start_date_time'] = event.previousData.startDate
    } 
    if (ganttTask.end_date_time !== event.previousData.endDate) {
      updateData['end_date_time'] = event.previousData.endDate
    }
    console.log(`update: ${updateData['start_date_time']}, level: ${level}`)
    switch (level) {
      case 0:
        // If level is 0, then update project
        updateProject(event.data, updateData);
        break;
      case 1:
        // If level is 1, then update resource
        updateResource(event.data, updateData);
        break;
      case 2:
        // If level is 2, then update task
        updateTask(event.data, updateData);
        break;
      default:
        throw new Error('Invalid level');
    }
  }
  const toolbarClickHandler: EmitType<ClickEventArgs> = (args: ClickEventArgs) => {
    switch (args?.item?.id) {
      case EGanttToolbarItem.ZoomIn:
        ganttChartRef?.current?.zoomIn();
        break;
      case EGanttToolbarItem.ZoomOut:
        ganttChartRef?.current?.zoomOut();
        break;
      case EGanttToolbarItem.ExpandAll:
        ganttChartRef?.current?.expandAll();
        break;
      case EGanttToolbarItem.CollapseAll:
        ganttChartRef?.current?.collapseAll();
        break;
        
    }
    // if (ganttChartRef?.current?.currentZoomingLevel?.level === customZoomingLevels.length - 1) {
    //   setDisableZoomIn(true);
    // } else {
    //   setDisableZoomIn(false);
    // }
    // if (ganttChartRef?.current?.currentZoomingLevel?.level === 0) {
    //   setDisableZoomOut(true);
    // } else {
    //   setDisableZoomOut(false);
    // }
  };

  const onClickUserPopover = (event: React.MouseEvent<HTMLDivElement>) => {
    setUserPopoverAnchorElement(event?.currentTarget?.id === userPopoverAnchorElement?.id ? null : event?.currentTarget);
  };

  const onCloseUserPopover = () => {
    setUserPopoverAnchorElement(null);
  };

  return (
    <Fragment>
      <div className={classes.container}>
        <div className={classes.header}>
          <Select
            customTheme={ESelectTheme.Header}
            value={{ value: workspace?._id, label: workspace?.name }}
            options={workspaceOptions}
            onChange={(option: any) => {
              dispatch(setProjectListReducer(null));
              dispatch(setArchivedProjectListReducer(null));
              dispatch(setWorkspaceReducer(workspaceList?.find((workspace: IWorkspace) => workspace?._id === option?.value)));
              dispatch(
                push({
                  pathname: routes.private.project.scheduler.replace(':workspaceId', option?.value),
                  search: `&workspaceId=${option?.value ?? NULL_VALUE}`,
                })
              );
            }}
          />
        </div>

        <div className={classes.toolbar}>
          <div className={classes.toolbarLeft}>
            <input className={classes.searchInput} type="text" placeholder="Search" />
            <Select
              customTheme={ESelectTheme.Filter}
              placeholder="Project"
              options={projectOptions?.length > 0 ? [ALL_OPTION, ...projectOptions] : [NULL_OPTION]}
              onChange={(option: any) => {
                dispatch(setProjectListReducer(projectList?.filter((project: IProject) => project?._id === option?.value)));
                dispatch(
                  push({
                    pathname: routes.private.project.scheduler.replace(':workspaceId', workspace?._id),
                    search: `&projectId=${option?.value ?? NULL_VALUE}`,
                  })
                );
              }}
            />
            <Select customTheme={ESelectTheme.Filter} placeholder="Pipeline" />
            <Select customTheme={ESelectTheme.Filter} placeholder="User" />
            <Select customTheme={ESelectTheme.Filter} placeholder="Status" />
            {/* <div className={classes.switchContainer}>
                <Stack direction="row" alignItems="center" gap={1}>
                  <p>Hide Project Layer</p>
                  <Switch
                    checked={hideProjectLayer}
                    onChange={() => {
                      setHideProjectLayer(!hideProjectLayer);
                    }}
                  />
                </Stack>
            </div> */}
            <div className={classes.switchContainer}>
              <Stack direction="row" alignItems="center" gap={1}>
                <p>Hide Labels</p>
                <Switch
                  checked={hideLabels}
                  onChange={() => {
                    setHideLabels(!hideLabels);
                  }}
                />
              </Stack>
            </div>
            <div className={classes.switchContainer}>
              <Stack direction="row" alignItems="center" gap={1}>
                <p>Hide Grid</p>
                <Switch
                  checked={gridLines === 'None'}
                  onChange={() => {
                    // console.log('gridLines', gridLines)
                    setGridlines(gridLines === 'None' ? 'Both' : 'None');
                  }}
                />
              </Stack>
            </div>
          </div>
        </div>
        <div className={classes.chart}
          // className={clsx(classes.chart, {
          //   [classes.hideProjectLayer]: hideProjectLayer && !dataBinding,
          // })}
        >
          <GanttComponent
            dataSource={ganttTaskList}
            ref={ganttChartRef}
            // treeColumnIndex={1}
            
            // Appearance
            height="100%"
            rowHeight={40}
            taskbarHeight={22}
            gridLines={gridLines}
            splitterSettings={{
              position: '30%',
            }}
            connectorLineBackground="var(--primary)"
            allowResizing={true}
            highlightWeekends={true}

            // Booleans
            delayUpdate={false}
            allowSelection={true}
            // allowSorting={true}
            // showColumnMenu={true}
            editSettings={{ allowEditing: true, allowAdding: false, allowDeleting: false, allowTaskbarEditing: true }}
            enablePredecessorValidation={false}
            allowUnscheduledTasks={true}
            
            // Settings
            selectionSettings={{ cellSelectionMode: 'Box', mode: 'Row' }}
            taskFields={taskFields} 
            taskMode="Manual"
            durationUnit="Day"

            // Actions
            taskbarEdited={(event: ITaskbarEditedEventArgs) => {
              console.log('taskbarEdited event', event);
              updateTaskBarDates(event);
            }}
            toolbarClick={toolbarClickHandler}
            toolbar={[
              {
                id: EGanttToolbarItem.ExpandAll,
                tooltipText: 'Expand all',
                template: () => {
                  return <PrimaryButton className={classes.toolbarButton}>Expand all</PrimaryButton>;
                },
              },
              {
                id: EGanttToolbarItem.CollapseAll,
                tooltipText: 'Collapse all',
                template: () => {
                  return <PrimaryButton className={classes.toolbarButton}>Collapse all</PrimaryButton>;
                },
              },
              {
                id: EGanttToolbarItem.ZoomIn,
                tooltipText: 'Zoom in',
                template: () => {
                  return (
                    <PrimaryButton className={classes.toolbarButton} disabled={disableZoomIn}>
                      Zoom in <ExpandIcon />
                    </PrimaryButton>
                  );
                },
              },
              {
                id: EGanttToolbarItem.ZoomOut,
                tooltipText: 'Zoom out',
                template: () => {
                  return (
                    <PrimaryButton className={classes.toolbarButton} disabled={disableZoomOut}>
                      Zoom out <CollapseIcon />
                    </PrimaryButton>
                  );
                },
              },
              {
                id: EGanttToolbarItem.CsvExport,
                tooltipText: 'Export to CSV',
                template: () => {
                  return  (
                  <PrimaryButton className={classes.toolbarButton}>
                    Export to CSV <DownloadIcon />
                  </PrimaryButton>
                  );
                },
              }
            ]}
            cellEdit={(event: any) => {
              console.log('cellEdit event', event);
            }}
            // dataBound={() => setDataBinding(false)}
          >
            <ColumnsDirective>
              <ColumnDirective field="_id" headerText="ID" width="60" visible={false}></ColumnDirective>
              <ColumnDirective field="name" headerText="Name" width="60"></ColumnDirective>
              <ColumnDirective field="dates" headerText="Dates" minWidth="100" width="140" template={dateTemplate}></ColumnDirective>
              <ColumnDirective field="progress" headerText="Progress" minWidth="110" width="110" template={progressTemplate} visible={showProgress}></ColumnDirective>
              <ColumnDirective field="assigned_user" headerText="Assign" width="80" template={assignTemplate} visible={showAssignedUser}></ColumnDirective>
            </ColumnsDirective>
            <Inject services={[Toolbar, Edit, Selection]} />
          </GanttComponent>
        </div>
        <div className={classes.footer}>
          <p className={classes.instructions}>Shift + Mouse Wheel to navigate Left/Right</p>
        </div>
      </div>

      <UserPopover
        anchorElement={userPopoverAnchorElement}
        onClose={onCloseUserPopover}
        onSelect={(user: ICollabUser) => {
          const ganttData = ganttTaskList?.find((task) => task._id === updateResourcePayload.current?.id);
          console.log('ganttData', ganttData);
          // updateTask(ganttData, {assigned_user_id: user._id});
        }}
      />
    </Fragment>
  );
});

export default SchedulerPage;
