import { Fragment, memo, SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';
import classes from './styles.module.scss';
import {
  BackIcon,
  CheckCircleIcon,
  CloseIcon,
  CopyLinkIcon,
  DeleteIcon,
  DownloadIcon,
  FilterIcon,
  MediaThumbnailPlaceholderImage,
  MoreIcon,
  NextIcon,
  PauseIcon,
  PinGreenIcon,
  PinPinkIcon,
  PinPurpleIcon,
  PinWhiteIcon,
  PlayIcon,
  PreviousIcon,
  RepeatIcon,
  ReplyIcon,
  SortUpIcon,
  UserPlaceholderImage,
  VideoThumbnailPlaceholderImage,
  VolumeIcon,
} from 'assets';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { useDispatch, useSelector } from 'react-redux';
import { push, replace } from 'connected-react-router';
import { Dropdown } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { IProject } from 'interfaces/project';
import { IReducer } from 'redux/reducers';
import { ALL_OPTION, NULL_OPTION, NULL_VALUE } from 'configs/constant';
import { IMessage, IPin, IPipeline, IResource, IResourceMedia, IReview } from 'interfaces/pipeline';
import { getResourceListRequest, getResourceMediaRequest, setResourceListReducer, setResourceMediaReducer, setResourceReducer } from 'redux/reducers/Information/actionTypes';
import { routes } from 'routers/routes';
import { Chip, Menu, MenuItem, Skeleton, Slider } from '@mui/material';
import ApiService from 'services/api_service';
import ApiRoutes from 'configs/apiRoutes';
import { setIsLoadingReducer } from 'redux/reducers/Status/actionTypes';
import ToastService from 'services/toast_service';
import Messages from 'configs/messages';
import { saveAs } from 'file-saver';
import { ISelectOption } from 'interfaces/common';
import Select from 'components/Select';
import { ESelectTheme } from 'configs/enums';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { CustomArrowProps, Settings, default as SlickSlider } from 'react-slick';
import { setPipelineReducer, setProjectReducer } from 'redux/reducers/Workspace/actionTypes';
import useRouteMatch from 'hooks/useRouteMatch';
import { clsx } from 'clsx';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import QueryString from 'qs';
import Loading from 'components/Loading';
import CommonService from 'services/common_service';
import ReactPlayer from 'react-player';
import { OnProgressProps } from 'react-player/base';

const VIDEO_TIME_OFFSET = 1;

dayjs.extend(relativeTime);

const NextArrow = ({ currentSlide, slideCount, ...props }: CustomArrowProps) => <NextIcon {...props} />;
const PrevArrow = ({ currentSlide, slideCount, ...props }: CustomArrowProps) => <PreviousIcon {...props} />;

interface IQueryString {
  workspaceId?: string;
  subResourceId?: string;
  mediaId?: string;
}

interface IVideoState {
  ready: boolean;
  playing: boolean;
  time: number;
  loop: boolean;
  changingVolume: boolean;
  volume: number;
}

interface IExpandReplyReview {
  reviewId: string;
  showInput: boolean;
  text: string;
  showError: boolean;
}

interface FramePageProps {}

const FramePage: React.FC<FramePageProps> = memo((props: FramePageProps) => {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const {
    params: { projectId, pipelineId, resourceId },
  } = useRouteMatch(routes.private.project.frame);

  const queryParams: IQueryString = QueryString.parse(window?.location?.search);

  const { user } = useSelector((state: IReducer) => state?.user);
  const { workspace, resourceTable, project, pipeline } = useSelector((state: IReducer) => state.workspace);
  const { projectList, resourceList, pipelineList, resourceMedia } = useSelector((state: IReducer) => state.information);

  const mainSliderRef = useRef<SlickSlider>(null);
  const thumbnailSliderRef = useRef<SlickSlider>(null);
  const videoPlayer = useRef<ReactPlayer[]>([]);

  const [activeMediaIndex, setActiveMediaIndex] = useState<number>(0);
  const [activeMedia, setActiveMedia] = useState<IResourceMedia>(null);
  const [projectOptions, setProjectOptions] = useState<ISelectOption[]>([]);
  const [pipelineOptions, setPipelineOptions] = useState<ISelectOption[]>([]);
  const [resourceOptions, setResourceOptions] = useState<ISelectOption[]>([]);
  const [subResourceOptions, setSubResourceOptions] = useState<ISelectOption[]>([]);
  const [selectedResource, setSelectedResource] = useState<ISelectOption>(null);
  const [isOnlyOneMedia, setIsOnlyOneMedia] = useState<boolean>(false);
  const [isPinning, setIsPinning] = useState<boolean>(false);
  const [currentPin, setCurrentPin] = useState<{ pin: IPin; reviewId: string; time?: number }>(null);
  const [message, setMessage] = useState<string>(null);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [reviews, setReviews] = useState<IReview[]>(null);
  const [pins, setPins] = useState<IPin[]>([]);
  const [temporaryResolveStatus, setTemporaryResolveStatus] = useState<{ reviewId: string; status: boolean }>(null);
  const [showResolvedReview, setShowResolvedReview] = useState<boolean>(false);
  const [sortByDate, setSortByDate] = useState<boolean>(false);
  const [activeReview, setActiveReview] = useState<IReview>(null);
  const [selectedSubResource, setSelectedSubResource] = useState<ISelectOption>(null);
  const [intactResourceMedia, setIntactResourceMedia] = useState<IResourceMedia[]>(null);
  const [isBoundRelevantSubResource, setIsBoundRelevantSubResource] = useState<boolean>(null);
  const [signedMediaUrl, setSignedMediaUrl] = useState<{ loading: boolean; url: string; mediaId: string }>(null);
  const [videoState, setVideoState] = useState<IVideoState>(null);
  const [expandReplyReview, setExpandReview] = useState<IExpandReplyReview>(null);
  const [anchorEl, setAnchorEl] = useState<null | SVGElement>(null);
  const [messageAction, setMessageAction] = useState<{ data: IMessage; edit: boolean; reviewId: string }>(null);

  const mainSlideSetting: Settings = useMemo(
    () => ({
      dots: false,
      infinite: false,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      draggable: false,
      fade: true,
      cssEase: 'linear',
    }),
    []
  );
  const thumbnailSlideSetting: Settings = useMemo(
    () => ({
      centerMode: true,
      centerPadding: '0px',
      dots: false,
      infinite: false,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      draggable: false,
      focusOnSelect: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    }),
    []
  );

  useEffect(() => {
    if (projectList) {
      setProjectOptions(
        projectList?.map((project: IProject) => {
          return {
            value: project?._id,
            label: project?.name,
          };
        })
      );
    }
  }, [projectList]);

  useEffect(() => {
    if (pipelineList?.data) {
      setPipelineOptions(
        pipelineList?.data?.map((pipeline: IPipeline) => {
          return {
            value: pipeline?._id,
            label: pipeline?.name,
          };
        })
      );
    }
  }, [pipelineList]);

  useEffect(() => {
    dispatch(setResourceReducer({
      id: resourceId
    }))
  }, [resourceId]);

  useEffect(() => {
    if (resourceList?.data) {
      setResourceOptions(
        resourceList?.data?.map((resource: IResource) => {
          return {
            value: resource?._id,
            label: resource?.name,
          };
        })
      );
    }
  }, [resourceList]);

  useEffect(() => {
    if (queryParams?.mediaId && resourceMedia?.data?.length && !resourceMedia?.isLoading) {
      const mediaIndex = resourceMedia?.data?.findIndex((media) => media?._id === queryParams?.mediaId);
      if (mediaIndex > 0) {
        mainSliderRef?.current?.slickGoTo(mediaIndex, true);
      }
    }
  }, [queryParams?.mediaId, resourceMedia, mainSliderRef]);

  useEffect(() => {
    if (selectedSubResource && !resourceMedia?.isLoading && resourceMedia?.data && resourceMedia?.resource_id === resourceId) {
      if (selectedSubResource?.value !== ALL_OPTION?.value) {
        if (!isBoundRelevantSubResource) {
          const intactMedia = intactResourceMedia ? intactResourceMedia : [...(resourceMedia?.data ?? [])];
          if (!intactResourceMedia) {
            setIntactResourceMedia(intactMedia);
          }
          dispatch(
            setResourceMediaReducer({
              ...resourceMedia,
              data: intactMedia?.filter((media) => media?.sub_resource_id === selectedSubResource?.value),
            })
          );
          setIsBoundRelevantSubResource(true);
        }
      } else {
        if (intactResourceMedia) {
          dispatch(
            setResourceMediaReducer({
              ...resourceMedia,
              data: intactResourceMedia,
            })
          );
          setIntactResourceMedia(null);
        }
      }
    }
  }, [intactResourceMedia, selectedSubResource, resourceMedia, resourceId]);

  useEffect(() => {
    if (!resourceList?.isLoading && resourceList?.resourceConfigurations?.length) {
      setSubResourceOptions(resourceList?.resourceConfigurations?.map((resource) => ({ value: resource?._id, label: resource?.name })));
    }
  }, [resourceList]);

  useEffect(() => {
    if (message?.trim()?.length === 0) {
      setShowErrorMessage(true);
    } else {
      setShowErrorMessage(false);
    }
  }, [message]);

  useEffect(() => {
    if (activeReview && (!reviews || !reviews?.length)) {
      setActiveReview(null);
    }
  }, [reviews, activeReview]);

  useEffect(() => {
    if (subResourceOptions?.length) {
      if (resourceId) {
        setSelectedSubResource(subResourceOptions?.find((option) => option?.value === queryParams?.subResourceId) ?? ALL_OPTION);
      } else {
        setSelectedSubResource(ALL_OPTION);
      }
    }
  }, [queryParams?.subResourceId, subResourceOptions]);

  useEffect(() => {
    if (currentPin?.pin && currentPin?.reviewId) {
      dispatch(setIsLoadingReducer(true));
      ApiService.PATCH(ApiRoutes.review.update.replace(':reviewId', currentPin?.reviewId), {
        ...(currentPin?.time ? { time: currentPin?.time } : {}),
        pin: {
          x: currentPin?.pin?.x,
          y: currentPin?.pin?.y,
        },
      })
        .then((response) => {
          const updatedReview = response?.entities?.[0];
          setReviews(reviews?.map((review) => (review?._id === updatedReview?._id ? updatedReview : review)));
          setCurrentPin(null);
          setIsPinning(false);
          ToastService.success(Messages.success.default);
        })
        .catch((error) => {
          console.log(error);
          ToastService.error(Messages.error.default);
        })
        .finally(() => dispatch(setIsLoadingReducer(false)));
    }
  }, [currentPin]);

  useEffect(() => {
    setVideoState(null);
  }, [activeMediaIndex]);

  useEffect(() => {
    if (reviews?.length > 0 && !resourceMedia.isLoading && !resourceMedia?.data?.length) {
      setReviews([]);
    }
  }, [reviews, resourceMedia]);

  useEffect(() => {
    if (!resourceMedia?.isLoading && resourceMedia?.data?.length > 0) {
      setReviews(null);
      dispatch(setIsLoadingReducer(true));
      ApiService.GET(
        ApiRoutes.review.info
          .replace(':resourceMediaId', resourceMedia?.data?.map((media) => media?._id)?.join(','))
          .replace(':isResolved', `${showResolvedReview}`),
        {
          ...(sortByDate
            ? {
                sortBy: 'createdAt',
                sortDirection: 'desc',
              }
            : {}),
        }
      )
        .then((response) => {
          setReviews(response?.entities ?? []);
        })
        .catch((error) => {
          setReviews([]);
          console.log(error);
          ToastService.error(Messages.error.default);
        })
        .finally(() => dispatch(setIsLoadingReducer(false)));
    } else {
      if (!resourceMedia?.isLoading) {
        setReviews([]);
      }
    }
  }, [resourceMedia, showResolvedReview, sortByDate]);

  useEffect(() => {
    if (!resourceMedia?.isLoading && resourceMedia?.data?.length && !resourceList?.isLoading && resourceList?.data?.length) {
      const resource = resourceList?.data?.find((resource) => resource?._id === resourceMedia?.resource_id);
      setIsOnlyOneMedia(resourceMedia?.data?.length === 1);
      if (resource) {
        setSelectedResource({ value: resource?._id, label: resource?.name });
      }
    }
  }, [resourceMedia, resourceList]);

  useEffect(() => {
    if (resourceMedia?.data && !resourceMedia?.isLoading) {
      const media = resourceMedia?.data?.[activeMediaIndex];
      if (media) {
        setActiveMedia(media);
        setSignedMediaUrl({
          loading: true,
          url: null,
          mediaId: null,
        });
        ApiService.POST(ApiRoutes.resource.signedUrl, {
          key: media?.key,
        })
          .then((response) => {
            setSignedMediaUrl({
              loading: false,
              url: response?.url,
              mediaId: media?._id,
            });
          })
          .catch((error) => {
            console.log(error);
            ToastService.error(Messages.error.default);
          });
      }
    } else {
      setActiveMedia(null);
    }
  }, [resourceMedia, activeMediaIndex]);

  useEffect(() => {
    if (activeReview && !resourceMedia?.isLoading && resourceMedia?.data?.length) {
      setExpandReview({
        reviewId: activeReview?._id,
        showInput: true,
        text: null,
        showError: false,
      });
      const mediaIndex = resourceMedia?.data?.findIndex((media) => activeReview?.resource_media_id === media?._id);
      if (mediaIndex >= 0 && activeMediaIndex !== mediaIndex) {
        mainSliderRef?.current?.slickGoTo(mediaIndex, true);
      }
    }
  }, [activeMediaIndex, activeReview, resourceMedia, mainSliderRef?.current]);

  useEffect(() => {
    const pinsOfMedia: IPin[] = reviews
      ?.filter((review) => review?.resource_media_id === activeMedia?._id && review?.pin && review?.pin?.x >= 0 && review?.pin?.y >= 0)
      ?.map((review) => ({
        ...review?.pin,
        reviewId: review?._id,
        review_resolved: review?.is_resolved,
        time: review?.time,
      }));
    if (currentPin?.pin) {
      pinsOfMedia?.push(currentPin?.pin);
    }
    setPins(pinsOfMedia ?? []);
  }, [reviews, currentPin, activeMedia]);

  useEffect(() => {
    setCurrentPin(null);
    setIsPinning(false);
    setIntactResourceMedia(null);
    if (resourceList?.data?.length && !resourceList?.isLoading && resourceList?.projectId === projectId && resourceList?.pipelineId === pipelineId) {
      if (resourceId !== NULL_VALUE) {
        const resource = resourceList?.data?.find((resource: IResource) => resource?._id === resourceId);
        if (resource) {
          setSelectedResource({ value: resource?._id, label: resource?.name });
          if (!resourceMedia?.isLoading) {
            dispatch(getResourceMediaRequest(resource?._id));
          }
        }
      } else {
        const resource = resourceList?.data?.[0];
        setSelectedResource({ value: resource?._id, label: resource?.name });
        dispatch(getResourceMediaRequest(resource?._id));
        dispatch(
          replace({
            pathname: pathname?.replace(resourceId, resource?._id),
            search: search,
          })
        );
      }
    }
  }, [resourceId, resourceList, projectId, pipelineId]);

  useEffect(() => {
    if (pipelineId && pipelineId !== NULL_VALUE) {
      if (projectId && !resourceList?.isLoading && (resourceList?.projectId !== projectId || resourceList?.pipelineId !== pipelineId)) {
        dispatch(
          getResourceListRequest(
            projectId,
            pipelineId,
            resourceTable?.searchKey,
            resourceTable?.priority,
            resourceTable?.users,
            resourceTable?.status,
            resourceTable?.page,
            resourceTable?.limit,
            resourceTable?.totalPages,
            resourceTable?.column,
            resourceTable?.direction,
            false,
            resourceList?.resourceConfigurations
          )
        );
      }
    } else {
      if (resourceList?.projectId !== projectId) {
        dispatch(setResourceListReducer({ projectId, pipelineId: null, data: [], isLoading: false, resourceConfigurations: [], isPublished: true }));
      }
    }
  }, [projectId, pipelineId, resourceTable, resourceList]);

  const onDownloadMedia = () => {
    if (activeMedia && signedMediaUrl?.url) {
      saveAs(signedMediaUrl?.url, activeMedia?.fileName);
    }
  };

  const onPlayPauseVideo = () => {
    setVideoState({
      ...videoState,
      playing: !videoState?.playing,
    });
  };

  const onChangeRepeatVideo = () => {
    setVideoState({
      ...videoState,
      loop: !videoState?.loop,
    });
  };

  const onChangeVolumeVideo = () => {
    setVideoState({
      ...videoState,
      changingVolume: !videoState?.changingVolume,
    });
  };

  const onDeleteImage = () => {
    if (activeMedia) {
      dispatch(setIsLoadingReducer(true));
      ApiService.DELETE(ApiRoutes.resource.media.delete.replace(':resourceMediaId', activeMedia?._id))
        .then(() => {
          const remainMedia = resourceMedia?.data?.filter((media) => media?._id !== activeMedia?._id);
          dispatch(
            setResourceMediaReducer({
              ...resourceMedia,
              data: remainMedia,
            })
          );
          if (remainMedia?.length === 0) {
            setActiveMediaIndex(null);
          }
          ToastService.success(Messages.success.deleted);
        })
        .catch((error) => {
          console.log(error);
          ToastService.error(Messages.error.default);
        })
        .finally(() => dispatch(setIsLoadingReducer(false)));
    }
  };

  const onCopyUrl = () => {
    navigator?.clipboard
      .writeText(window?.location?.href ?? 'N/A')
      .then(() => {
        ToastService.success(Messages.success.copied);
      })
      .catch((error) => {
        console.log(error);
        ToastService.error(Messages.error.default);
      });
  };

  const onSwipeMedia = (_, nextSlide: number) => {
    setActiveMediaIndex(nextSlide);
    setCurrentPin(null);
    const query = { ...queryParams };
    const media = resourceMedia?.data?.[nextSlide];
    query.mediaId = media?._id;
    dispatch(
      push({
        pathname: pathname,
        search: QueryString.stringify(query, { encode: false }),
      })
    );
  };

  const onPinning = () => {
    setIsPinning(!isPinning);
    if (isPinning) {
      setCurrentPin(null);
    }
  };

  const onUpdatePin = (reviewId: string) => {
    if (isPinning && currentPin?.reviewId === reviewId) {
      setIsPinning(false);
      setCurrentPin(null);
    } else {
      setIsPinning(true);
      setCurrentPin({
        pin: null,
        reviewId: reviewId,
      });
    }
  };

  const onPin = (e: React.MouseEvent<HTMLImageElement>) => {
    if (isPinning) {
      if (activeMedia?.content_type?.includes('image')) {
        const imageElement = e?.currentTarget;
        const rect = imageElement?.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        const width = imageElement?.width ?? 0;
        const height = imageElement?.height ?? 0;
        const pin: IPin = {
          x: x / width,
          y: y / height,
        };
        setCurrentPin({
          pin,
          reviewId: currentPin?.reviewId ?? null,
        });
      } else {
        setVideoState({ ...videoState, playing: false });
        const videoWrapper = e?.currentTarget;
        const rect = videoWrapper?.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        const width = videoWrapper?.clientWidth ?? 0;
        const height = videoWrapper?.clientHeight ?? 0;
        const pin: IPin = {
          x: x / width,
          y: y / height,
        };
        setCurrentPin({
          pin,
          reviewId: currentPin?.reviewId ?? null,
          time: videoPlayer?.current?.[activeMediaIndex]?.getCurrentTime(),
        });
      }
    } else {
      setCurrentPin(null);
    }
  };

  const onSubmitMessage = () => {
    if (!message || message?.length === 0) {
      setMessage('');
    } else {
      dispatch(setIsLoadingReducer(true));
      ApiService.POST(ApiRoutes.review.default, {
        resource_media_id: activeMedia?._id,
        ...(currentPin?.time ? { time: currentPin?.time } : {}),
        messages: [
          {
            text: message,
          },
        ],
        ...(currentPin
          ? {
              pin: {
                x: currentPin?.pin?.x,
                y: currentPin?.pin?.y,
              },
            }
          : {}),
      })
        .then((response) => {
          setMessage(null);
          setCurrentPin(null);
          setIsPinning(false);
          setReviews([...reviews, response?.entities?.[0]]);
          ToastService.success(Messages.success.reviewSent);
        })
        .catch((error) => {
          console.log(error);
          ToastService.error(Messages.error.default);
        })
        .finally(() => dispatch(setIsLoadingReducer(false)));
    }
  };

  const onRemovePin = (reviewId: string) => {
    dispatch(setIsLoadingReducer(true));
    ApiService.PATCH(ApiRoutes.review.update.replace(':reviewId', reviewId), {
      pin: {
        x: -1,
        y: -1,
      },
    })
      .then((response) => {
        const updatedReview = response?.entities?.[0];
        setReviews(reviews?.map((review) => (review?._id === updatedReview?._id ? updatedReview : review)));
        ToastService.success(Messages.success.deleted);
      })
      .catch((error) => {
        console.log(error);
        ToastService.error(Messages.error.default);
      })
      .finally(() => dispatch(setIsLoadingReducer(false)));
  };

  const updateResolveStatus = (review: IReview) => {
    setTemporaryResolveStatus({ reviewId: review?._id, status: !review?.is_resolved });
    dispatch(setIsLoadingReducer(true));
    ApiService.PATCH(ApiRoutes.review.update.replace(':reviewId', review?._id), {
      is_resolved: !review?.is_resolved,
    })
      .then((response) => {
        const updatedReview = response?.entities?.[0];
        setReviews(reviews?.filter((review) => review?._id !== updatedReview?._id)?.map((review) => review));
        ToastService.success(Messages.success.default);
      })
      .catch((error) => {
        console.log(error);
        ToastService.error(Messages.error.default);
      })
      .finally(() => {
        dispatch(setIsLoadingReducer(false));
        setTemporaryResolveStatus(null);
      });
  };

  const onActiveReview = (review: IReview) => {
    setActiveReview(review?._id === activeReview?._id ? null : review);
    if (review?.time) {
      videoPlayer?.current?.[activeMediaIndex]?.seekTo(review?.time, 'seconds');
    }
  };

  const onShowReply = (reviewId: string) => {
    setExpandReview({
      reviewId: reviewId,
      showInput: false,
      text: null,
      showError: false,
    });
  };

  const onHideReply = () => {
    setExpandReview(null);
  };

  const onStartReply = () => {
    if (expandReplyReview) {
      setExpandReview({
        ...expandReplyReview,
        showInput: true,
      });
    }
  };

  const onReplyMessage = () => {
    if (expandReplyReview && !expandReplyReview?.showError && expandReplyReview?.text?.trim()?.length > 0) {
      dispatch(setIsLoadingReducer(true));
      ApiService.POST(ApiRoutes.review.message.replace(':reviewId', activeReview?._id), {
        text: expandReplyReview?.text,
      })
        .then((response) => {
          const updatedReview: IReview = response?.entities?.[0];
          setExpandReview({
            ...expandReplyReview,
            text: '',
            showError: false,
          });
          setReviews(reviews?.map((review) => (review?._id === updatedReview?._id ? updatedReview : review)));
          ToastService.success(Messages.success.messageSent);
        })
        .catch((error) => {
          console.log(error);
          ToastService.error(Messages.error.default);
        })
        .finally(() => dispatch(setIsLoadingReducer(false)));
    } else {
      setExpandReview({
        ...expandReplyReview,
        showError: true,
      });
    }
  };

  const onMenuClose = () => {
    setAnchorEl(null);
  };

  const onEndEdit = () => {
    setMessageAction(null);
  };

  const onEditMessage = () => {
    if (messageAction && messageAction?.data?.text?.trim()?.length > 0) {
      dispatch(setIsLoadingReducer(true));
      ApiService.PATCH(ApiRoutes.review.message.replace(':reviewId', messageAction?.reviewId) + `/${messageAction?.data?._id}`, {
        text: messageAction?.data?.text,
      })
        .then((response) => {
          const updatedReview: IReview = response?.entities?.[0];
          setReviews(reviews?.map((review) => (review?._id === updatedReview?._id ? updatedReview : review)));
          setMessageAction(null);
          ToastService.success(Messages.success.default);
        })
        .catch((error) => {
          console.log(error);
          ToastService.error(Messages.error.default);
        })
        .finally(() => dispatch(setIsLoadingReducer(false)));
    }
  };

  return (
    <div
      className={clsx(classes.page, {
        [classes.isPinning]: isPinning,
      })}
    >
      <div className={classes.header}>
        <div
          className={classes.back}
          onClick={() => {
            if (intactResourceMedia) {
              dispatch(
                setResourceMediaReducer({
                  ...resourceMedia,
                  data: intactResourceMedia,
                })
              );
            }
            dispatch(
              push({
                pathname: routes.private.project.taskView.replace(':projectId', projectId).replace(':pipelineId', pipelineId),
                search: `&resourceId=${resourceId ?? NULL_VALUE}&workspaceId=${workspace?._id ?? NULL_VALUE}`,
              })
            );
          }}
        >
          <BackIcon />
          <p>Back</p>
        </div>

        <div className={classes.fileName}>{activeMedia?.fileName ?? ''}</div>

        <div className={classes.rightFilter}>
          {projectOptions?.length ? (
            <Select
              customTheme={ESelectTheme.RoundedGradient}
              placeholder="Project"
              value={{ value: project?._id, label: project?.name }}
              options={projectOptions?.length > 0 ? projectOptions : [NULL_OPTION]}
              onChange={(option) => {
                const selectedProject = projectList?.find((project: IProject) => project?._id === (option as ISelectOption)?.value);
                setSelectedResource(null);
                setReviews(null);
                setActiveMediaIndex(null);
                dispatch(setProjectReducer(selectedProject));
                dispatch(setResourceMediaReducer(null));
                setSubResourceOptions([]);
                dispatch(
                  push({
                    pathname: pathname
                      ?.replace(projectId, selectedProject?._id)
                      ?.replace(pipelineId, selectedProject?.pipeline_ids?.length ? selectedProject?.pipeline_ids?.[0] : null)
                      ?.replace(resourceId, NULL_VALUE),
                    search,
                  })
                );
              }}
            />
          ) : (
            <Skeleton variant="rectangular" width={104} height={38} sx={{ borderRadius: '16px', background: 'var(--backgroundDark)' }} />
          )}

          {!pipelineList?.isLoading && pipelineOptions?.length ? (
            <Select
              customTheme={ESelectTheme.RoundedGradient}
              placeholder="Pipeline"
              value={{ value: pipeline?._id, label: pipeline?.name }}
              options={pipelineOptions?.length > 0 ? pipelineOptions : [NULL_OPTION]}
              onChange={(option) => {
                const selectedPipeline = pipelineList?.data?.find((pipeline: IPipeline) => pipeline?._id === (option as ISelectOption)?.value);
                setSelectedResource(null);
                setReviews(null);
                setActiveMediaIndex(null);
                dispatch(setPipelineReducer(selectedPipeline));
                dispatch(
                  push({
                    pathname: pathname?.replace(pipelineId, selectedPipeline?._id)?.replace(resourceId, NULL_VALUE),
                    search,
                  })
                );
              }}
            />
          ) : (
            <Skeleton variant="rectangular" width={104} height={38} sx={{ borderRadius: '16px', background: 'var(--backgroundDark)' }} />
          )}

          {!resourceList?.isLoading && resourceList?.data ? (
            <Select
              customTheme={ESelectTheme.RoundedGradient}
              placeholder="Resource"
              value={selectedResource}
              options={resourceOptions?.length > 0 ? resourceOptions : []}
              onChange={(option) => {
                const selectedResource = resourceList?.data?.find((resource: IResource) => resource?._id === (option as ISelectOption)?.value);
                setSelectedResource({ value: selectedResource?._id, label: selectedResource?.name });
                setReviews(null);
                setActiveMediaIndex(null);
                dispatch(
                  push({
                    pathname: pathname?.replace(resourceId, selectedResource?._id),
                    search,
                  })
                );
              }}
            />
          ) : (
            <Skeleton variant="rectangular" width={104} height={38} sx={{ borderRadius: '16px', background: 'var(--backgroundDark)' }} />
          )}

          {subResourceOptions?.length ? (
            <Select
              customTheme={ESelectTheme.RoundedGradient}
              placeholder="Item_name"
              value={selectedSubResource}
              options={subResourceOptions?.length > 0 ? [ALL_OPTION, ...subResourceOptions] : [NULL_OPTION]}
              onChange={(option) => {
                setSelectedSubResource(option as ISelectOption);
                setIsBoundRelevantSubResource(false);
                setActiveMediaIndex(0);
                const query = { ...queryParams };
                if ((option as ISelectOption)?.value === ALL_OPTION?.value) {
                  delete query.subResourceId;
                } else {
                  query.subResourceId = `${(option as ISelectOption)?.value}`;
                }
                dispatch(
                  push({
                    pathname: pathname,
                    search: QueryString.stringify(query, { encode: false }),
                  })
                );
              }}
            />
          ) : (
            <Skeleton variant="rectangular" width={67} height={38} sx={{ borderRadius: '16px', background: 'var(--backgroundDark)' }} />
          )}
        </div>
      </div>

      <div className={classes.container}>
      
        <div className={classes.left}>
        
        {!resourceMedia?.isLoading && !resourceList?.isLoading ? (
          <div className={classes.reviewFrame}>
            <div className={classes.frame}>
              {!resourceMedia?.isLoading && !resourceList?.isLoading ? (
                resourceMedia?.data?.length ? (
                  <div
                    className={clsx(classes.mediasContainer, {
                      [classes.currentVideo]: activeMedia?.content_type?.includes('video'),
                    })}
                  >
                    <div className={classes.sliderContainer}>
                      <SlickSlider
                        {...mainSlideSetting}
                        ref={mainSliderRef}
                        beforeChange={onSwipeMedia}
                        asNavFor={thumbnailSliderRef.current}
                        initialSlide={0}
                        onInit={() => {
                          setActiveMediaIndex(0);
                        }}
                      >
                        {resourceMedia?.data?.map((media, mediaIndex) => (
                          <div className={classes.mediaContainer} key={mediaIndex}>
                            {media?.content_type?.includes('image') ? (
                              <>
                                {!signedMediaUrl?.loading ? (
                                  <img className={classes.mediaItem} src={signedMediaUrl?.url ?? media?.url} alt="Frame" onClick={onPin} />
                                ) : (
                                  <Loading />
                                )}
                              </>
                            ) : (
                              <>
                                {!signedMediaUrl?.loading && signedMediaUrl?.url && signedMediaUrl?.mediaId === media?._id ? (
                                  <div onClick={onPin} className={classes.videoWrapper}>
                                    <ReactPlayer
                                      ref={(el) => (videoPlayer.current[mediaIndex] = el)}
                                      playing={videoState?.playing ?? false}
                                      pip={false}
                                      volume={videoState?.volume ?? 0.5}
                                      loop={videoState?.loop ?? false}
                                      url={signedMediaUrl?.url}
                                      progressInterval={100}
                                      onReady={() => {
                                        setVideoState({
                                          ...videoState,
                                          ready: true,
                                          volume: 0.5,
                                        });
                                      }}
                                      onProgress={(e: OnProgressProps) => {
                                        setVideoState({
                                          ...videoState,
                                          time: e.playedSeconds,
                                        });
                                      }}
                                      onPlay={() => {
                                        setVideoState({
                                          ...videoState,
                                          playing: true,
                                        });
                                      }}
                                      onPause={() => {
                                        setVideoState({
                                          ...videoState,
                                          playing: false,
                                        });
                                      }}
                                    />
                                    {!videoState?.ready ? <Loading className={classes.loadingVideoReady} /> : null}
                                  </div>
                                ) : (
                                  <Loading />
                                )}
                              </>
                            )}
                            {!signedMediaUrl?.loading && signedMediaUrl?.url ? (
                              <>
                                {pins?.map((pin, pinIndex) => (
                                  <PinPinkIcon
                                    id={`${pin?.time - videoState?.time}`}
                                    key={pin?.reviewId ?? pinIndex}
                                    className={clsx(classes.pin, {
                                      [classes.display]: pin?.time ? Math?.abs(pin?.time - videoState?.time) <= VIDEO_TIME_OFFSET : true,
                                      [classes.resolved]: pin?.review_resolved,
                                      [classes.active]: pin?.reviewId && activeReview?._id === pin?.reviewId,
                                    })}
                                    style={{ top: pin?.y ? `calc(${pin?.y * 100}% - 30px)` : 0, left: `${pin?.x * 100}%` }}
                                  />
                                ))}
                              </>
                            ) : null}
                          </div>
                        ))}
                      </SlickSlider>
                    </div>
                  </div>
                ) : 
                <div className={classes.mediasContainer}>
                  { selectedResource && selectedResource?.value ? (
                    <h4>
                      No resource media found for: "{selectedResource?.label}" {selectedSubResource?.value !== 'ALL_OPTION_VALUE' ? ' -> "' + selectedSubResource?.label + "\"" : ''}
                    </h4>
                  ) : <h4>No resource media found</h4>
                }
              </div>
              ) : (
                <Skeleton variant="rectangular" width="100%" height={500} sx={{ borderRadius: '8px', background: 'var(--backgroundLight)' }} />
              )}

              {activeMedia?.content_type?.includes('video') ? (
                <div className={classes.videoTimelineWrapper}>
                  <Slider
                    className={classes.slider}
                    disabled={!videoState?.ready}
                    size="small"
                    value={
                      videoState?.time && videoPlayer?.current?.[activeMediaIndex]?.getDuration()
                        ? (videoState?.time * 100) / videoPlayer?.current?.[activeMediaIndex]?.getDuration()
                        : 0
                    }
                    defaultValue={0}
                    onChange={(_, value: number | number[], __) => {
                      setVideoState({
                        ...videoState,
                        playing: false,
                      });
                      videoPlayer?.current?.[activeMediaIndex]?.seekTo((value as number) / 100, 'fraction');
                    }}
                    onChangeCommitted={() => {
                      setVideoState({
                        ...videoState,
                        playing: true,
                      });
                    }}
                    aria-label="Small"
                    valueLabelDisplay="off"
                  />
                  {videoState?.ready
                    ? pins
                        ?.filter((pin) => pin?.time)
                        ?.map((pin, pinIndex) => (
                          <PinPurpleIcon
                            key={pin?.reviewId ?? pinIndex}
                            className={classes.timelinePin}
                            style={{
                              left:
                                pin?.time && videoPlayer?.current?.[activeMediaIndex]?.getDuration()
                                  ? `${(pin?.time * 100) / videoPlayer?.current?.[activeMediaIndex]?.getDuration()}%`
                                  : 0,
                            }}
                            onClick={() => {
                              videoPlayer?.current?.[activeMediaIndex]?.seekTo(pin?.time, 'seconds');
                            }}
                          />
                        ))
                    : null}
                </div>
              ) : null}

              <div className={classes.toolbar}>
                {resourceMedia?.data?.length && !resourceMedia?.isLoading && !resourceList?.isLoading ? (
                  <>
                    {videoState?.ready ? (
                      activeMedia?.content_type?.includes('video') ? (
                        <>
                          <div className={classes.videoToolbar}>
                            {videoState?.playing ? <PauseIcon onClick={onPlayPauseVideo} /> : <PlayIcon onClick={onPlayPauseVideo} />}
                            <RepeatIcon
                              className={clsx(classes.videoRepeat, {
                                [classes.enable]: videoState?.loop,
                              })}
                              onClick={onChangeRepeatVideo}
                            />
                            <VolumeIcon onClick={onChangeVolumeVideo} />
                          </div>
                          <Slider
                            aria-label="Volume"
                            className={clsx(classes.volumeSlider, {
                              [classes.hidden]: !videoState?.changingVolume,
                            })}
                            value={videoState?.volume ? videoState?.volume * 100 : 0}
                            onChange={(_, value: number | number[], __) => {
                              setVideoState({
                                ...videoState,
                                volume: (value as number) / 100,
                              });
                            }}
                          />
                        </>
                      ) : null
                    ) : null}
                    <DownloadIcon onClick={onDownloadMedia} />
                    <DeleteIcon onClick={onDeleteImage} />
                    <CopyLinkIcon onClick={onCopyUrl} />
                  </>
                ) : (
                  <Skeleton variant="rectangular" width="100%" height={60} sx={{ borderRadius: '8px', background: 'var(--backgroundLight)' }} />
                )}
              </div>
            </div>

            <div style={{ marginBottom: 8, padding: '0 32px' }}>
              {resourceMedia?.data?.length && !resourceMedia?.isLoading && !resourceList?.isLoading ? (
                <div
                  className={clsx(classes.thumbnailsContainer, {
                    [classes.onlyOneMedia]: isOnlyOneMedia,
                  })}
                >
                  <SlickSlider {...thumbnailSlideSetting} ref={thumbnailSliderRef} asNavFor={mainSliderRef.current} initialSlide={0}>
                    {resourceMedia?.data?.map((media, mediaIndex) => (
                      <img
                        key={mediaIndex}
                        src={
                          media?.thumbnail_blob ??
                          (media?.content_type?.substring(0, media?.content_type?.indexOf('/')) === 'image'
                            ? MediaThumbnailPlaceholderImage
                            : VideoThumbnailPlaceholderImage)
                        }
                        alt="Thumbnail"
                        onError={(event: SyntheticEvent<HTMLImageElement, Event>) => {
                          event.currentTarget.src =
                            media?.content_type?.substring(0, media?.content_type?.indexOf('/')) === 'image'
                              ? MediaThumbnailPlaceholderImage
                              : VideoThumbnailPlaceholderImage;
                        }}
                      />
                    ))}
                  </SlickSlider>
                </div>
              ) : (
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={73}
                  sx={{ borderRadius: '8px', marginBottom: 2, background: 'var(--backgroundLight)' }}
                  animation={resourceMedia?.isLoading ? 'wave' : false}
                />
              )}
            </div>

            <div className={classes.commentContainer}>
              <div className={classes.comment}>
                <div className={classes.avatar}>
                  <img src={user?.profile_image_thumbnail_blob ?? user?.profile_image_url ?? UserPlaceholderImage} alt="Avatar" />
                </div>

                <div className={classes.input}>
                  <textarea
                    autoFocus
                    disabled={resourceMedia?.isLoading || !resourceMedia?.data}
                    value={message ?? ''}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                      setMessage(e.target.value);
                    }}
                  />
                  {showErrorMessage ? <p className={classes.errorMessage}>Message required.</p> : null}
                </div>

                {!resourceMedia?.isLoading && resourceMedia?.data?.length ? (
                  <div className={classes.action}>
                    <div>
                      {isPinning && !currentPin?.reviewId ? <PinGreenIcon onClick={onPinning} /> : <PinWhiteIcon onClick={onPinning} />}
                      <PrimaryButton onClick={onSubmitMessage}>Save</PrimaryButton>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          ) : (
            <Skeleton variant="rectangular" width="100%" height="100%" sx={{ borderRadius: '8px', background: 'var(--backgroundLight)' }} animation={resourceMedia?.isLoading ? 'pulse' : false} />
          )
        }
        </div>

        <div className={classes.right}>
          <div className={classes.filter}>
            <Dropdown>
              <Dropdown.Toggle>
                <FilterIcon />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    setShowResolvedReview(!showResolvedReview);
                  }}
                >
                  {showResolvedReview ? <span>Show unresolved comments</span> : <span>Show resolved comments</span>}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setSortByDate(!sortByDate);
                  }}
                >
                  <span>
                    Sort by date{' '}
                    <SortUpIcon
                      className={clsx({
                        [classes.revert]: sortByDate,
                      })}
                    />{' '}
                  </span>
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    console.log(3);
                  }}
                >
                  <span>Sort by unread</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          {reviews && !resourceList?.isLoading && !resourceMedia?.isLoading ? (
            reviews?.length ? (
              reviews?.map((review, index) => {
                const mainMessage = review?.messages?.[0];
                const replyMessages = review?.messages?.slice(1);
                return (
                  <div key={index}>
                    <div
                      key={mainMessage?._id}
                      className={clsx(classes.commentBox, {
                        [classes.active]: activeReview?._id === review?._id,
                      })}
                      onClick={() => {
                        onActiveReview(review);
                      }}
                    >
                      <div className={classes.title}>
                        <div>
                          <img src={mainMessage?.user?.profile_image_thumbnail_blob ?? mainMessage?.user?.profile_image_url ?? UserPlaceholderImage} alt="Avatar" />
                          <p>{CommonService.getFullName(mainMessage?.user)}</p>
                        </div>
                        <div>
                          {review?.pin && review?.pin?.x >= 0 && review?.pin?.y >= 0 ? (
                            <PinGreenIcon className={classes.pinRed} onClick={() => onRemovePin(review?._id)} />
                          ) : (
                            <PinWhiteIcon
                              onClick={(e: React.MouseEvent<HTMLOrSVGElement>) => {
                                e.stopPropagation();
                                onUpdatePin(review?._id);
                              }}
                              className={clsx({
                                [classes.isPinning]: currentPin?.reviewId === review?._id,
                              })}
                            />
                          )}
                          <CheckCircleIcon
                            className={clsx({
                              [classes.notResolved]:
                                temporaryResolveStatus?.reviewId === review?._id ? !temporaryResolveStatus?.status : !review?.is_resolved,
                            })}
                            onClick={(e: React.MouseEvent<HTMLOrSVGElement>) => {
                              e.stopPropagation();
                              updateResolveStatus(review);
                            }}
                          />
                          <MoreIcon
                            onClick={(e: React.MouseEvent<SVGElement>) => {
                              setAnchorEl(e.currentTarget);
                              setMessageAction({
                                data: mainMessage,
                                edit: false,
                                reviewId: review?._id,
                              });
                            }}
                          />
                        </div>
                      </div>

                      {messageAction?.data?._id === mainMessage?._id && messageAction?.edit ? (
                        <div>
                          <div className={classes.inputWrapper}>
                            <input
                              type="text"
                              value={messageAction?.data?.text}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setMessageAction({
                                  ...messageAction,
                                  data: {
                                    ...messageAction?.data,
                                    text: e?.target?.value,
                                  },
                                });
                              }}
                            />
                            <ReplyIcon onClick={onEditMessage} className={classes.replyButton} />
                            <CloseIcon onClick={onEndEdit} className={classes.closeButton} />
                          </div>
                          {messageAction?.data?.text?.trim()?.length === 0 ? (
                            <p className={classes.errorMessage} style={{ marginLeft: 0 }}>
                              Message required.
                            </p>
                          ) : null}
                        </div>
                      ) : (
                        <p className={classes.content}>{mainMessage?.text}</p>
                      )}

                      <Chip
                        className={classes.subResourceChip}
                        label={subResourceOptions?.find((subResource) => subResource?.value === review?.sub_resource_id)?.label ?? 'N/A'}
                      />

                      <p className={classes.info}>{resourceMedia?.data?.find((media) => media?._id === review?.resource_media_id)?.fileName}</p>

                      <p className={classes.info}>{dayjs(mainMessage?.createdAt).fromNow()}</p>
                    </div>
                    {replyMessages?.length && expandReplyReview?.reviewId !== review?._id ? (
                        <p
                          className={classes.showMoreReply}
                          onClick={() => {
                            onShowReply(review?._id);
                            setActiveReview(review);
                          }}
                          >{`Show ${replyMessages?.length} ${replyMessages?.length === 1 ? 'reply' : 'replies'}`}</p>
                        ) : null
                    }

                    {expandReplyReview?.reviewId === review?._id ? (
                      <>
                        {replyMessages?.map((replyMessage) => (
                          <div key={replyMessage?._id} className={clsx(classes.commentBox, classes.replyThread)}>
                            <div className={classes.title}>
                              <div>
                                <img src={replyMessage?.user?.profile_image_thumbnail_blob ?? replyMessage?.user?.profile_image_url ?? UserPlaceholderImage} alt="Avatar" />
                                <p>{CommonService.getFullName(replyMessage?.user)}</p>
                              </div>

                              <div>
                                <MoreIcon
                                  onClick={(e: React.MouseEvent<SVGElement>) => {
                                    setAnchorEl(e.currentTarget);
                                    setMessageAction({
                                      data: replyMessage,
                                      edit: false,
                                      reviewId: review?._id,
                                    });
                                  }}
                                />
                              </div>
                            </div>

                            {messageAction?.data?._id === replyMessage?._id && messageAction?.edit ? (
                              <div>
                                <div className={classes.inputWrapper}>
                                  <input
                                    type="text"
                                    value={messageAction?.data?.text}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                      setMessageAction({
                                        ...messageAction,
                                        data: {
                                          ...messageAction?.data,
                                          text: e?.target?.value,
                                        },
                                      });
                                    }}
                                  />
                                  <ReplyIcon onClick={onEditMessage} className={classes.replyButton} />
                                  <CloseIcon onClick={onEndEdit} className={classes.closeButton} />
                                </div>
                                {messageAction?.data?.text?.trim()?.length === 0 ? (
                                  <p className={classes.errorMessage} style={{ marginLeft: 0 }}>
                                    Message required.
                                  </p>
                                ) : null}
                              </div>
                            ) : (
                              <p className={classes.content}>{replyMessage?.text}</p>
                            )}

                            <p className={classes.info}>{dayjs(replyMessage?.createdAt).fromNow()}</p>
                          </div>
                        ))}

                        {expandReplyReview?.reviewId === review?._id && !expandReplyReview?.showInput ? (
                          <div className={classes.replyAction}>
                            <p onClick={onHideReply}>Hide replies</p>
                            <button onClick={onStartReply}>Reply</button>
                          </div>
                        ) : null}

                        {expandReplyReview?.reviewId === review?._id && expandReplyReview?.showInput ? (
                          <>
                            <div className={classes.replyWrapper}>
                              <div className={classes.avatar}>
                                <img src={user?.profile_image_thumbnail_blob ?? user?.profile_image_url ?? UserPlaceholderImage} alt="Avatar" />
                              </div>
                              <div className={classes.inputWrapper}>
                                <input
                                  type="text"
                                  value={expandReplyReview?.text ?? ''}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setExpandReview({
                                      ...expandReplyReview,
                                      text: e?.target?.value,
                                      showError: e.target.value?.length === 0,
                                    });
                                  }}
                                />
                                <ReplyIcon onClick={onReplyMessage} />
                              </div>
                            </div>
                            {expandReplyReview?.showError ? <p className={classes.errorMessage}>Message required.</p> : null}
                          </>
                        ) : null}
                      </>
                    ) : null}

                    {index === reviews?.length - 1 ? null : <hr style={{ margin: '8px 0' }} />}
                  </div>
                );
              })
            ) : (
              <p className={classes.noReview} style={{ whiteSpace: 'pre-line' }}>There is currently no feedback!<br/>Start the conversation by leaving a comment.</p>
            )
          ) : (
            <Skeleton variant="rectangular" width={284} height="100%" sx={{ borderRadius: '16px', background: 'var(--backgroundDark)' }} />
          )}
        </div>
      </div>

      <Menu
        className={classes.messageMenu}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={onMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          onClick={() => {
            setMessageAction({
              ...messageAction,
              edit: true,
            });
            onMenuClose();
          }}
        >
          Edit message
        </MenuItem>
      </Menu>
    </div>
  );
});

export default FramePage;
